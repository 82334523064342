import { useHistory, useLocation } from "react-router-dom";
import { useState } from "react";
import "./newProduct.css";
import {
  addProduct,
  getCategories,
  getProduct,
  getRanges,
  removeAsset,
  updateProduct,
  uploadAsset,
} from "../../redux/apiRequests";
import { useDispatch, useSelector } from "react-redux";
import { Container, Input, Text, Row, Spacer, Button } from "@nextui-org/react";
import { ToastContainer, toast, Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import FileUpload from "../../components/fileUpload/FileUpload";
import { resetCurrentProduct, resetProduct } from "../../redux/productRedux";
import Composer from "../../components/composer/Composer";
import {
  ContentState,
  convertToRaw,
  convertFromRaw,
  EditorState,
} from "draft-js";
import htmlToDraft from "html-to-draftjs";
import Select from "react-select";
import { publicRequest, userRequest } from "../../requestMethods";

export default function NewCategory() {
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const history = useHistory();
  const dispatch = useDispatch();
  const product = useSelector((state) => state.product.product);
  const [categories, setCategories] = useState([]);
  const [ranges, setRanges] = useState([]);
  const [files, setFiles] = useState([]);
  const [currentLanguage, setCurrentLanguage] = useState("fr");
  const [inputs, setInputs] = useState({});
  const [helper, setHelper] = useState({
    name: {
      text: "",
      color: "",
    },
    description: {
      text: "",
      color: "",
    },
    category: {
      text: "",
      color: "",
    },
    range: {
      text: "",
      color: "",
    },
    image: {
      text: "",
      color: "",
    },
  });
  const [loading, setLoading] = useState(false);
  const isDuplicate = useSelector((state) => state.range.isDuplicate);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [filePond, setFilePond] = useState(null);
  const user = useSelector((state) => state.user.currentUser);

  const html =
    "<p>Saisissez la <strong>description</strong> du produit ici 😀</p><br /> <p>Vous pouvez utiliser les outils ci-dessus pour mieux présenter le produit en faisant des paragraphes ou définir des titres par section par exemple.</p>";

  const html_en =
    "<p>Enter the <strong>description</strong> of the product here 😀</p><br /> <p>You can use the tools above to better present the product by making paragraphs or setting titles per section for example.</p>";
  const contentBlock = htmlToDraft(html);
  const contentState = ContentState.createFromBlockArray(
    contentBlock.contentBlocks
  );
  const contentBlockEn = htmlToDraft(html_en);
  const contentStateEn = ContentState.createFromBlockArray(
    contentBlockEn.contentBlocks
  );
  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(contentState)
  );
  const [editorStateEn, setEditorStateEn] = useState(
    EditorState.createWithContent(contentStateEn)
  );

  const [defaultRange, setDefaultRange] = useState();
  const [defaultCategories, setDefaultCategories] = useState();

  const [isLoadingRanges, setIsLoadingRange] = useState(false);
  const [isLoadingCategories, setIsLoadingCategories] = useState(false);

  useEffect(async () => {
    // get published ranges
    try {
      setIsLoadingCategories(true);
      const pubcats = await publicRequest.get("/categories/all?sort=desc&published=true");
      setCategories(pubcats.data);
      setIsLoadingCategories(false);
    } catch (error) { }

    try {
      setIsLoadingRange(true);
      const pubranges = await publicRequest.get("/ranges/all?sort=desc&published=true");
      setRanges(pubranges.data);
      setIsLoadingRange(false);
    } catch (error) { }
  }, [dispatch]);

  useEffect(() => {
    dispatch(resetCurrentProduct());
    // reset inputs
    setInputs({});
    setDefaultCategories();
    setDefaultRange();
  }, []);

  useEffect(() => {
    // componentWillUnmount&& product?.range?._id
    return () => {
      setEditorState(EditorState.createWithContent(contentState));
      setEditorStateEn(EditorState.createWithContent(contentStateEn));
    };
  }, [product]);

  useEffect(() => {
    id && getProduct(id, dispatch);
  }, [id]);

  useEffect(() => {
    id &&
      product &&
      setFiles(
        product.images.map((image) => {
          return {
            source: `https://laboluxecosmetique.com/assets/products/${image}`,
            options: {
              type: "local",
            },
          };
        })
      );

    product &&
      setEditorState(
        EditorState.createWithContent(
          convertFromRaw(JSON.parse(product.description))
        )
      );
    product &&
      setEditorStateEn(
        EditorState.createWithContent(
          convertFromRaw(JSON.parse(product.en.description))
        )
      );

    !id || !product
      ? inputs.range &&
      setDefaultRange({
        value: inputs?.range,
        label: ranges?.find((range) => range._id === inputs?.range)?.name,
      })
      : product.range ?
        setDefaultRange({
          value: product?.range?._id,
          label: product?.range?.name,
        }) : setDefaultRange();

    !id || !product
      ? inputs.categories &&
      setDefaultCategories(
        inputs?.categories?.map((category) => {
          return {
            value: category?._id,
            label: categories?.find((cat) => cat?._id === category)?.name,
          };
        })
      )
      : setDefaultCategories(
        product?.categories?.map((category) => {
          return {
            value: category._id,
            label: category.name,
          };
        })
      );
  }, [product]);

  useEffect(() => {
    setHelper((prev) => {
      return {
        ...prev,
        description: {
          text: "",
          color: "",
        },
      };
    });
  }, [editorState]);

  useEffect(() => {
    if (isDuplicate) {
      toast.error("La gamme que vous essayer d'ajouter existe déjà !", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        transition: Flip,
      });
    }
  }, [isDuplicate]);

  const handleChange = (e) => {
    setInputs((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
    resetHelpers(e);
  };

  const handleSelectChange = (e) => {
    setInputs((prev) => {
      return { ...prev, range: e?.value };
    });
    setDefaultRange(e);
    resetHelpers(e);
  };

  const handleMultiSelectChange = (e) => {
    setInputs((prev) => {
      return {
        ...prev,
        categories: e.map((item) => {
          return item.value;
        }),
      };
    });
    setDefaultCategories(e);
    resetHelpers(e);
  };

  const handleLanguageChange = (e) => {
    setCurrentLanguage(e.target.value);
  };

  const resetHelpers = (e) => {
    e?.target?.name === "name" &&
      setHelper((prev) => {
        return {
          ...prev,
          name: {
            text: "",
            color: "",
          },
        };
      });

    e?.target?.name === "description" &&
      setHelper((prev) => {
        return {
          ...prev,
          description: {
            text: "",
            color: "",
          },
        };
      });

    (e?.target?.name || e?.name) === "category" &&
      setHelper((prev) => {
        return {
          ...prev,
          category: {
            text: "",
            color: "",
          },
        };
      });

    (e?.target?.name || e?.name) === "range" &&
      setHelper((prev) => {
        return {
          ...prev,
          range: {
            text: "",
            color: "",
          },
        };
      });

    e?.target?.name === "image" &&
      setHelper((prev) => {
        return {
          ...prev,
          image: {
            text: "",
            color: "",
          },
        };
      });
  };

  const handleClick = async (e) => {
    e.preventDefault();

    inputs.name &&
      setHelper((prev) => {
        return {
          ...prev,
          name: {
            text: "",
            color: "",
          },
        };
      });

    inputs.name_en &&
      setHelper((prev) => {
        return {
          ...prev,
          name: {
            text: "",
            color: "",
          },
        };
      });

    !product &&
      !inputs.name &&
      currentLanguage === "en" &&
      setHelper((prev) => {
        return {
          ...prev,
          name: {
            text: "La version en français est manquante. Veuillez changer de langue en cliquant sur la sélection en haut à droite",
            color: "error",
          },
        };
      });

    !product &&
      !inputs.name_en &&
      currentLanguage === "fr" &&
      setHelper((prev) => {
        return {
          ...prev,
          name: {
            text: "La version anglaise est manquante. Veuillez changer de langue en cliquant sur la sélection en haut à droite",
            color: "error",
          },
        };
      });

    !product &&
      !inputs.name_en &&
      currentLanguage === "en" &&
      setHelper((prev) => {
        return {
          ...prev,
          name: {
            text: "Le nom (anglais) du nouveau produit ne peut être vide",
            color: "error",
          },
        };
      });

    !product &&
      !inputs.name &&
      currentLanguage === "fr" &&
      setHelper((prev) => {
        return {
          ...prev,
          name: {
            text: "Le nom du nouveau produit ne peut être vide",
            color: "error",
          },
        };
      });

    !product &&
      convertToRaw(editorState.getCurrentContent()).blocks.length === 1 &&
      convertToRaw(editorState.getCurrentContent()).blocks[0].text === "" &&
      currentLanguage === "en" &&
      setHelper((prev) => {
        return {
          ...prev,
          description: {
            text: "La description(anglaise) du produit est obligatoire",
            color: "error",
          },
        };
      });

    if (
      (!product &&
        convertToRaw(editorState.getCurrentContent()).blocks.length === 1 &&
        convertToRaw(editorState.getCurrentContent()).blocks[0].text === "") ||
      (!product &&
        convertToRaw(editorState.getCurrentContent()).blocks.length === 2 &&
        convertToRaw(editorState.getCurrentContent()).blocks[0].text.indexOf(
          "Saisissez la description du produit ici 😀"
        ) !== -1)
    ) {
      currentLanguage === "fr" &&
        setHelper((prev) => {
          return {
            ...prev,
            description: {
              text: "La description du produit est obligatoire",
              color: "error",
            },
          };
        });
    }

    if (
      (!product &&
        inputs.name &&
        inputs.name_en &&
        convertToRaw(editorState.getCurrentContent()).blocks.length !== 1 &&
        convertToRaw(editorState.getCurrentContent()).blocks[0].text !== "") ||
      (!product &&
        inputs.name &&
        inputs.name_en &&
        convertToRaw(editorState.getCurrentContent()).blocks.length !== 2 &&
        convertToRaw(editorState.getCurrentContent()).blocks[0].text.indexOf(
          "Saisissez la description du produit ici 😀"
        ) === -1)
    ) {
      dispatch(resetProduct());
      setLoading(true);
      filePond &&
        filePond.getFiles().forEach(async (file) => {
          if (file.status !== 5) {
            setHelper((prev) => {
              return {
                ...prev,
                image: {
                  text: "L'image de couverture ne s'est pas chargée correctement. Veuillez réessayer",
                  color: "error",
                },
              };
            });
          } else {
            return;
          }
        });

      const rawStateDescription = JSON.stringify(
        convertToRaw(editorState.getCurrentContent())
      );
      const rawStateDescription_en = JSON.stringify(
        convertToRaw(editorStateEn.getCurrentContent())
      );

      var reduced = filePond?.getFiles().reduce(function (filtered, file) {
        if (file.status === 5) {
          filtered.push(file.filename);
        }
        return filtered;
      }, []);

      reduced && (
        reduced.forEach(async (file) => {
          await uploadAsset(file, dispatch)
        })
      );

      const slug = inputs.name_en.toLowerCase().replace(/ /g, "-");
      addProduct(
        reduced
          ? {
            slug: slug,
            name: inputs.name,
            description: rawStateDescription,
            categories: inputs.categories,
            range: inputs.range,
            en: {
              name: inputs.name_en,
              description: rawStateDescription_en,
            },
            images: reduced,
            updatedBy: user._id,
          }
          : {
            slug: slug,
            name: inputs.name,
            description: rawStateDescription,
            categories: inputs.categories,
            range: inputs.range,
            en: {
              name: inputs.name_en,
              description: rawStateDescription_en,
            },
            updatedBy: user._id,
          },
        categories,
        inputs.categories,
        ranges,
        inputs.range,
        dispatch
      )
        .then(() => {
          setLoading(false);
          if (!isDuplicate) {
            toast.success("Le produit a été bien ajoutée", {
              position: "bottom-right",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              transition: Flip,
            });
            setTimeout(() => {
              history.push("/products");
            }, 2000);
          }
        })
        .catch(() => {
          setLoading(false);
        });
    } else if (
      product &&
      (inputs.name ||
        inputs.name_en ||
        inputs.range ||
        inputs.categories ||
        ((convertToRaw(editorState.getCurrentContent()).blocks.length !== 0 &&
          convertToRaw(editorState.getCurrentContent()).blocks[0].text !== "") ||
          (convertToRaw(editorState.getCurrentContent()).blocks.length !== 2 &&
            convertToRaw(editorState.getCurrentContent()).blocks[0].text.indexOf(
              "Saisissez la description du produit ici 😀"
            ) === -1)) ||
        ((convertToRaw(editorStateEn.getCurrentContent()).blocks.length !== 0 &&
          convertToRaw(editorStateEn.getCurrentContent()).blocks[0].text !== "") ||
          (convertToRaw(editorStateEn.getCurrentContent()).blocks.length !== 2 &&
            convertToRaw(editorStateEn.getCurrentContent()).blocks[0].text.indexOf(
              "Saisissez la description du produit ici 😀"
            ) === -1)) ||
        filePond?.getFiles().reduce(function (filtered, file) {
          if (file.status === 5) {
            filtered.push(file.filename);
          }
          return filtered;
        }, []))
    ) {
      dispatch(resetProduct());
      setLoading(true);
      filePond &&
        filePond.getFiles().forEach(async (file) => {
          product &&
            product?.images.forEach((image) => {
              if (file.status === 5 && image.filename !== file.filename) {
                setHelper((prev) => {
                  return {
                    ...prev,
                    image: {
                      text: "L'image de couverture ne s'est pas chargée correctement. Veuillez réessayer",
                      color: "error",
                    },
                  };
                });
              } else {
                return;
              }
            });
        });

      const rawStateDescription = JSON.stringify(
        convertToRaw(editorState.getCurrentContent())
      );
      const rawStateDescription_en = JSON.stringify(
        convertToRaw(editorStateEn.getCurrentContent())
      );

      var reduced = filePond?.getFiles().reduce(function (filtered, file) {
        if (product && product?.images.length > 0) {
          product?.images.forEach((image) => {
            if (file.status === 5 && image !== file.filename) {
              filtered.push(file.filename);
            }
          });
        } else {
          if (file.status === 5) {
            filtered.push(file.filename);
          }
        }
        return filtered;
      }, []);

      filePond?.getFiles().length > 0 && reduced.length > 0 && (
        await product?.images.forEach(async (file) => {
          await removeAsset(file, dispatch);
        })
      );

      reduced && reduced?.length > 0 && (
        reduced.forEach(async (file) => {
          await uploadAsset(file, dispatch)
        })
      );

      const slug = inputs.name_en
        ? inputs.name_en.toLowerCase().replace(/ /g, "-")
        : product.slug;


      product?.range && await userRequest.put(`/ranges/${product?.range?._id}/product/${product?._id}`);


      inputs?.range && await userRequest.put(`/ranges/product/${product?._id}`, {
        newRange: inputs.range,
      });

      product?.categories && await userRequest.put(`/categories/product/${product?._id}/remove`, {
        categories: product?.categories,
      });

      inputs?.categories && await userRequest.put(`/categories/product/${product?._id}`, {
        newCategories: inputs.categories,
      });

      updateProduct(
        product._id,
        reduced && reduced?.length > 0
          ? {
            slug: slug,
            name: inputs.name ? inputs.name : product.name,
            description: rawStateDescription,
            categories: inputs.categories,
            range: inputs.range ?? null,
            en: {
              name: inputs.name_en ? inputs.name_en : product.en.name,
              description: rawStateDescription_en,
            },
            images: reduced,
            updatedBy: user._id,
          }
          : {
            slug: slug,
            name: inputs.name ? inputs.name : product.name,
            description: rawStateDescription,
            categories: inputs.categories,
            range: inputs.range ?? null,
            en: {
              name: inputs.name_en ? inputs.name_en : product.en.name,
              description: rawStateDescription_en,
            },
            updatedBy: user._id,
          },
        dispatch
      )
        .then(() => {
          setLoading(false);
          if (!isDuplicate) {
            toast.success("Le produit a été bien mise à jour", {
              position: "bottom-right",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              transition: Flip,
            });
            setTimeout(() => {
              history.push("/products");
            }, 2000);
          }
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const onStateChange = (state) => {
    setEditorState(state);
  };

  const onStateChangeEn = (state) => {
    setEditorStateEn(state);
  };

  return (
    <Container className="newProduct">
      <Spacer y={1} />
      <Row align="center" justify="space-between">
        <Text>
          <Text h1>
            {product ? "Modifier le " : "Nouveau "}
            produit
          </Text>
          <Text h3>{currentLanguage === "en" && "(version anglaise)"}</Text>
        </Text>
        <div className="select-container-language">
          <div className="select-wrapper">
            <select
              className="next-ui-select"
              name="language"
              onChange={handleLanguageChange}
            >
              <option value="fr">Français</option>
              <option value="en">Anglais</option>
            </select>
          </div>
        </div>
      </Row>
      <Row>
        <form className="addProductForm">
          {currentLanguage === "fr" && (
            <Input
              label="Nom"
              placeholder="Crème Mains bio - 40 G"
              size="large"
              width="100%"
              name="name"
              value={inputs.name}
              shadow={false}
              bordered
              initialValue={!id || !product ? null : product?.name}
              color={helper.name.color}
              helperColor={helper.name.color}
              helperText={helper.name.text}
              onChange={handleChange}
            />
          )}
          {currentLanguage === "en" && (
            <Input
              label="Nom"
              placeholder="Organic Hand Cream - 40 G"
              size="large"
              width="100%"
              name="name_en"
              value={inputs.name_en}
              shadow={false}
              bordered
              initialValue={!id || !product ? null : product?.en?.name}
              color={helper.name.color}
              helperColor={helper.name.color}
              helperText={helper.name.text}
              onChange={handleChange}
            />
          )}
          <Spacer y={1.5} />
          <Row>
            <Text color={helper.description.color}>Description</Text>
          </Row>
          <Spacer y={0.5} />
          {currentLanguage === "fr" && (
            <Composer editorState={editorState} onStateChange={onStateChange} />
          )}
          {currentLanguage === "en" && (
            <Composer
              editorState={editorStateEn}
              onStateChange={onStateChangeEn}
            />
          )}
          {helper.description.text && (
            <div class="helper-text-container with-value">
              <p class="helper-text">{helper.description.text}</p>
            </div>
          )}
          {currentLanguage === "fr" && <Spacer y={1.5} />}
          {currentLanguage === "fr" && <Spacer y={0.5} />}
          {currentLanguage === "fr" && (
            <Row className="select-input-container">
              <label className="select-input-label">Catégorie</label>
              <div className="select-container">
                <div className="multi-select-wrapper">
                  <Select
                    isMulti
                    name="categories"
                    options={categories.map((category) => {
                      return {
                        value: category._id,
                        label: category.name,
                      };
                    })}
                    value={defaultCategories}
                    className="next-ui-multi-select"
                    classNamePrefix=""
                    onChange={handleMultiSelectChange}
                    isLoading={isLoadingCategories}
                    loadingMessage={() => "Chargement..."}
                    loadingIndicator="true"
                    isClearable="true"
                    placeholder="Choisir une ou plusieurs catégorie"
                    noOptionsMessage={() => "Aucune catégorie trouvée"}
                  />
                </div>
              </div>
            </Row>
          )}
          {currentLanguage === "fr" && <Spacer y={1.5} />}
          {currentLanguage === "fr" && (
            <Row className="select-input-container">
              <label className="select-input-label">Gamme</label>
              <div className="select-container">
                <div className="multi-select-wrapper">
                  <Select
                    name="ranges"
                    options={ranges.map((range) => {
                      return {
                        value: range._id,
                        label: range.name,
                      };
                    })}
                    value={defaultRange}
                    className="next-ui-multi-select"
                    classNamePrefix=""
                    onChange={handleSelectChange}
                    isLoading={isLoadingRanges}
                    loadingMessage={() => "Chargement..."}
                    loadingIndicator="true"
                    isClearable="true"
                    placeholder="Choisir une gamme"
                    noOptionsMessage={() => "Aucune gamme trouvée"}
                  />
                </div>
              </div>
            </Row>
          )}
          {currentLanguage === "fr" && <Spacer y={1.5} />}
          {currentLanguage === "fr" && (
            <Row>
              <label>Image de couverture</label>
            </Row>
          )}
          {currentLanguage === "fr" && <Spacer y={0.5} />}
          {currentLanguage === "fr" && (
            <FileUpload
              maxFiles={5}
              setFilePond={setFilePond}
              files={files}
              setFiles={setFiles}
              uploadedFiles={uploadedFiles}
              setUploadedFiles={setUploadedFiles}
            />
          )}
          {currentLanguage === "fr" && !helper?.image?.text && (
            <Text h5 style={{ marginTop: "-0.5rem 0 0 10px" }}>
              Seule les images de type webp sont acceptées.
            </Text>
          )}
          {currentLanguage === "fr" && helper?.image?.text && (
            <Text
              style={{
                margin: "-0.5rem 0 0 10px",
                color: "#F21351",
                fontSize: "11.2px",
              }}
            >
              {helper.image.text}
            </Text>
          )}
          <Spacer y={1.5} />
          <Button
            loaderType="spinner"
            color="success"
            {...(loading && { loading })}
            onClick={handleClick}
          >
            {product ? "Modifier" : "Ajouter"}
          </Button>
          <Spacer y={1.5} />
        </form>
      </Row>
      <ToastContainer />
    </Container>
  );
}
